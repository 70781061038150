// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cn-armstrongs-global-expertise-in-healthcare-js": () => import("/opt/build/repo/src/pages/cn/armstrongs-global-expertise-in-healthcare.js" /* webpackChunkName: "component---src-pages-cn-armstrongs-global-expertise-in-healthcare-js" */),
  "component---src-pages-cn-online-courses-for-the-healthcare-industry-js": () => import("/opt/build/repo/src/pages/cn/online-courses-for-the-healthcare-industry.js" /* webpackChunkName: "component---src-pages-cn-online-courses-for-the-healthcare-industry-js" */),
  "component---src-pages-cn-ready-to-know-more-js": () => import("/opt/build/repo/src/pages/cn/ready-to-know-more.js" /* webpackChunkName: "component---src-pages-cn-ready-to-know-more-js" */),
  "component---src-pages-cn-reducing-risks-for-legionella-and-scalding-js": () => import("/opt/build/repo/src/pages/cn/reducing-risks-for-legionella-and-scalding.js" /* webpackChunkName: "component---src-pages-cn-reducing-risks-for-legionella-and-scalding-js" */),
  "component---src-pages-cn-the-threat-of-legionella-js": () => import("/opt/build/repo/src/pages/cn/the-threat-of-legionella.js" /* webpackChunkName: "component---src-pages-cn-the-threat-of-legionella-js" */),
  "component---src-pages-cn-world-class-hot-water-system-solutions-for-healthcare-js": () => import("/opt/build/repo/src/pages/cn/world-class-hot-water-system-solutions-for-healthcare.js" /* webpackChunkName: "component---src-pages-cn-world-class-hot-water-system-solutions-for-healthcare-js" */),
  "component---src-pages-en-armstrongs-global-expertise-in-healthcare-js": () => import("/opt/build/repo/src/pages/en/armstrongs-global-expertise-in-healthcare.js" /* webpackChunkName: "component---src-pages-en-armstrongs-global-expertise-in-healthcare-js" */),
  "component---src-pages-en-online-courses-for-the-healthcare-industry-js": () => import("/opt/build/repo/src/pages/en/online-courses-for-the-healthcare-industry.js" /* webpackChunkName: "component---src-pages-en-online-courses-for-the-healthcare-industry-js" */),
  "component---src-pages-en-ready-to-know-more-js": () => import("/opt/build/repo/src/pages/en/ready-to-know-more.js" /* webpackChunkName: "component---src-pages-en-ready-to-know-more-js" */),
  "component---src-pages-en-reducing-risks-for-legionella-and-scalding-js": () => import("/opt/build/repo/src/pages/en/reducing-risks-for-legionella-and-scalding.js" /* webpackChunkName: "component---src-pages-en-reducing-risks-for-legionella-and-scalding-js" */),
  "component---src-pages-en-the-threat-of-legionella-js": () => import("/opt/build/repo/src/pages/en/the-threat-of-legionella.js" /* webpackChunkName: "component---src-pages-en-the-threat-of-legionella-js" */),
  "component---src-pages-en-world-class-hot-water-system-solutions-for-healthcare-js": () => import("/opt/build/repo/src/pages/en/world-class-hot-water-system-solutions-for-healthcare.js" /* webpackChunkName: "component---src-pages-en-world-class-hot-water-system-solutions-for-healthcare-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

